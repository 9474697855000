import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import MediaQuery from "react-responsive";

import images from "src/images";
import { media, desktopWidth, mobileWidth } from "src/utils/media";
import { Button } from "../Typography";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const Container = styled(Modal)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 680px;
  box-shadow: 0px 10px 15px rgba(54, 62, 76, 0.3),
    0px 10px 15px rgba(54, 62, 76, 0.1), 0px 15px 40px rgba(54, 62, 76, 0.2);
  border-radius: 10px;
  overflow: hidden;
  ${media.mobile`
    width: 327px;
  `}
`;

const Content = styled.div`
  width: 100%;
`;

const WebinarContent = styled.img`
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
  padding-right: 44px;
  background: #ffffff;
  ${media.mobile`
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 0px;
    padding-right: 16px;
  `}
`;

const RightGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CloseButton = styled(Button)`
  width: 79px;
  color: #060607;
  text-align: center;
  ${media.mobile`
    width: 57px;
  `}
`;

const DetailButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 135px;
  height: 60px;
  color: #313a45;
  border: 1px solid #d7dbe0;
  border-radius: 1000px;
  margin-right: 16px;

  ${media.mobile`
    width: 97px;
    height: 38px;
    margin-right: 8px;
  `}
`;

const RegisterButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 60px;
  color: #ffffff;
  background: #2057ce;
  border-radius: 1000px;

  ${media.mobile`
    width: 109px;
    height: 38px;
  `}
`;

const customStyles: any = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.40)",
    zIndex: 10,
  },
  content: {
    outline: 0,
  },
};

const HenesisEthereumModal = (props: Props) => {
  const { isOpen, onClose } = props;

  const onDetail = () => {
    window.open("https://bit.ly/3ysgiHg");
  };

  const onRegister = () => {
    window.open("https://bit.ly/3vbl3D2");
  };

  return (
    <React.Fragment>
      <Container
        style={customStyles}
        isOpen={isOpen}
        onRequestClose={onClose}
        onAfterOpen={() => (document.body.style.overflow = "hidden")}
        onAfterClose={() => (document.body.style.overflow = "unset")}
      >
        <MediaQuery minWidth={desktopWidth}>
          <Inner>
            <WebinarContent src={images.henesisEthereumBg} />
            <Footer>
              <CloseButton onClick={onClose}>닫기</CloseButton>
              <RightGroup>
                <DetailButton onClick={onDetail}>자세히 보기</DetailButton>
                <RegisterButton onClick={onRegister}>
                  상담 신청하기
                </RegisterButton>
              </RightGroup>
            </Footer>
          </Inner>
        </MediaQuery>
        <MediaQuery maxWidth={mobileWidth}>
          <Inner>
            <WebinarContent src={images.henesisEthereumMobileBg} />
            <Footer>
              <CloseButton onClick={onClose}>닫기</CloseButton>
              <RightGroup>
                <DetailButton onClick={onDetail}>자세히 보기</DetailButton>
                <RegisterButton onClick={onRegister}>
                  상담 신청하기
                </RegisterButton>
              </RightGroup>
            </Footer>
          </Inner>
        </MediaQuery>
      </Container>
    </React.Fragment>
  );
};

export default HenesisEthereumModal;
