import { PageRendererProps } from "gatsby";
import React, { Component } from "react";
import i18n from "i18next";

import Layout from "src/components/Layout";
import IndexPage from "src/components/page/IndexPage";
import { storage } from "src/configs/cookieStorage";
import SEO from "src/components/SEO";
import HenesisEthereumModal from "src/components/modal/HenesisEthereumModal";
import { initialize as initializeI18next } from "src/configs/locale";

type Props = PageRendererProps;

type States = {
  isOpen: boolean;
};

initializeI18next("ko");

class HomePage extends Component<Props, States> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: storage().showFirstHenesisEthereumModal(),
    };
  }

  public componentDidMount() {
    i18n.changeLanguage("ko");
    storage().saveLanguage("ko");
    // initializeChannelIO("initializeChannelIOko");
  }

  public render() {
    const { isOpen } = this.state;

    return (
      <React.Fragment>
        <SEO
          description={`Henesis is the most powerful and secure crypto wallet API. Provide great user experience and secure digital assets with Henesis. You can integrate it within two days.`}
        />
        <IndexPage {...this.props} isKo={true} />
        <HenesisEthereumModal isOpen={isOpen} onClose={this.onClose} />
      </React.Fragment>
    );
  }

  private onClose = () => {
    this.setState({
      isOpen: false,
    });
  };
}

export default HomePage;
